import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'

//Utils
import { findOption, transformSelectField } from 'utils/helpers'
import { formatMonetaryNumber } from 'utils/numbers'

//Hooks
import useShipmentsEnumerations from 'hooks/useShipmentsEnumerations'
import useInvoiceEnumerations from 'hooks/useInvoiceEnumerations'

// Atlassian
import Select from '@atlaskit/select'
import Checkbox from '@atlaskit/checkbox'
import Textfield from '@atlaskit/textfield'
import { DynamicTableStateless } from '@atlaskit/dynamic-table'
import { ErrorMessage } from '@atlaskit/form'
import Button from '@atlaskit/button'

// Interfaces
import {
  Field,
  useFieldArray,
  useFormContext,
  useFormValues,
  useDisclosure,
} from '@royan-co/user-interface'

//Components
import Card from 'components/UI/Card'
import ImportRowModal from './ImportRowModal'

const CreateInvoiceFormRows = ({ isCreditNote }) => {
  const formValues = useFormValues()
  const { shipmentId } = useParams()
  const { setValue } = useFormContext()
  const isCumulative = !shipmentId

  const { getServiceCodeOptions, isLoadingShipmentEnumeration } = useShipmentsEnumerations()
  const { ivaCodeOptions, getIvaCodeRate, isLoadingInvoiceEnumerations } = useInvoiceEnumerations()

  const [isOpenImportRowModal, importRowModalHandlers] = useDisclosure('import-row')

  const { fields: rowsFields } = useFieldArray({ name: 'rows' })

  const hasInitializedIvaAmounts = useRef(false)

  const setIvaAmountValue = useCallback(
    (ivaCode, price, index) => {
      let ivaAmount = 0
      price = price * 1

      if (ivaCode && price) {
        const rate = getIvaCodeRate(ivaCode)
        ivaAmount = (rate * price) / 100 || 0
      }
      
      setValue(`rows.${index}.iva_amount`, ivaAmount.toFixed(2))
    },
    [getIvaCodeRate, setValue]
  )

  const selectedRows = useMemo(() => formValues?.rows?.filter((r) => !!r.checked), [formValues])

  const totalPrice = useMemo(
    () => selectedRows?.reduce((p, c) => p + c.sell_price * 1, 0) || 0,
    [selectedRows]
  )
  const totalIvaAmount = useMemo(
    () => selectedRows?.reduce((p, c) => p + c.iva_amount * 1, 0) || 0,
    [selectedRows]
  )
  const totalGrandPrice = useMemo(
    () => (totalPrice * 1 || 0) + (totalIvaAmount * 1 || 0),
    [totalIvaAmount, totalPrice]
  )

  const toggleAll = (checked) => {
    formValues?.rows?.forEach((row, i) => {
      setValue(`rows.${i}.checked`, checked)
    })
  }

  useEffect(() => {
    hasInitializedIvaAmounts.current = false;
  }, [formValues?.rows]);

  useEffect(() => {
    if (ivaCodeOptions.length !== 0 && !hasInitializedIvaAmounts.current) {
      formValues?.rows?.forEach((row, i) => {
        setIvaAmountValue(row?.iva_code, row?.sell_price, i)
      })

      hasInitializedIvaAmounts.current = true
    }
  }, [ivaCodeOptions, formValues?.rows, setIvaAmountValue])

  const rowsHead = {
    cells: [
      {
        key: 'checkbox',
        content: (
          <>
            <Checkbox
              onChange={(e) => toggleAll(e.target.checked)}
              isChecked={selectedRows?.length > 0}
              isIndeterminate={
                selectedRows?.length > 0 && formValues?.rows?.length > selectedRows?.length
              }
            />
          </>
        ),
      },
      {
        key: 'code',
        content: <span style={{ minWidth: '100px', display: 'inline-block' }}>Code</span>,
      },
      {
        key: 'description',
        content: <span style={{ minWidth: '130px', display: 'inline-block' }}>Description</span>,
      },
      {
        key: 'QTY',
        content: <span style={{ minWidth: '40px', display: 'inline-block' }}>QTY</span>,
      },
      {
        key: 'unit_price',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Unit price</span>,
      },
      {
        key: 'sell_price',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>Sell price</span>,
      },
      {
        key: 'sell_price2',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>IVA code</span>,
      },
      {
        key: 'IVA_amount',
        content: <span style={{ minWidth: '75px', display: 'inline-block' }}>IVA Amount</span>,
      },
    ],
  }

  const invoiceRows = useMemo(() => {
    let rowsItems = rowsFields?.map((p, i) => ({
      key: p.id,
      cells: [
        {
          content: (
            <Field type="checkbox" name={`rows.${i}.checked`} className="!mt-0 !cursor-pointer">
              {({ fieldProps }) => <Checkbox {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: (
            <Field
              type="select"
              name={`rows.${i}.service_code`}
              className="!mt-0"
              transform={transformSelectField(getServiceCodeOptions)}
              validate={(code) => {
                return !findOption(getServiceCodeOptions, code) && formValues?.rows?.[i]?.checked
                  ? false
                  : true
              }}
            >
              {({ fieldProps }) => (
                <div style={{ maxWidth: '150px' }}>
                  <Select
                    menuPlacement="auto"
                    placeholder="Choose"
                    menuPortalTarget={document.body}
                    isLoading={isLoadingShipmentEnumeration}
                    options={getServiceCodeOptions}
                    {...fieldProps}
                  />
                </div>
              )}
            </Field>
          ),
        },
        {
          content: (
            <Field name={`rows.${i}.description`} defaultValue="" className="!mt-0">
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: (
            <Field name={`rows.${i}.quantity`} isDisabled defaultValue="" className="!mt-0">
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: (
            <Field
              name={`rows.${i}.unit_price`}
              isDisabled={!isCreditNote}
              defaultValue=""
              className="!mt-0"
              validate={(price) => {
                return +price === 0 && formValues?.rows?.[i]?.checked && isCreditNote ? false : true
              }}
            >
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
        {
          content: (
            <Field
              name={`rows.${i}.sell_price`}
              isDisabled={!isCreditNote}
              defaultValue=""
              className="!mt-0"
              validate={(price) => {
                return +price === 0 && formValues?.rows?.[i]?.checked ? false : true
              }}
            >
              {({ fieldProps, error }) => (
                <>
                  <Textfield
                    {...fieldProps}
                    onChange={(e) => {
                      setIvaAmountValue(formValues.rows[i].iva_code, e.target.value, i)
                      fieldProps.onChange(e)
                    }}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
              )}
            </Field>
          ),
        },

        {
          content: (
            <Field
              type="select"
              name={`rows.${i}.iva_code`}
              className="!mt-0"
              transform={{
                output: (opt) => {
                  setIvaAmountValue(opt?.value, formValues.rows[i].sell_price, i)
                  return opt?.value !== undefined ? opt.value : null
                },
                input: (v) => findOption(ivaCodeOptions, v),
              }}
            >
              {({ fieldProps }) => (
                <div style={{ width: '150px' }}>
                  <Select
                    menuPlacement="auto"
                    placeholder="Choose"
                    isClearable
                    menuPortalTarget={document.body}
                    isLoading={isLoadingInvoiceEnumerations}
                    options={ivaCodeOptions}
                    {...fieldProps}
                  />
                </div>
              )}
            </Field>
          ),
        },
        {
          content: (
            <Field name={`rows.${i}.iva_amount`} isDisabled defaultValue="" className="!mt-0">
              {({ fieldProps }) => <Textfield {...fieldProps} />}
            </Field>
          ),
        },
      ],
    }))

    rowsItems.push(
      {
        key: `invoiceRows-total`,
        cells: [
          {
            content: '',
          },
          {
            content: <strong>Total</strong>,
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: formatMonetaryNumber(totalPrice) + ' EUR',
          },
          {
            content: '',
          },
          {
            content: formatMonetaryNumber(totalIvaAmount) + ' EUR',
          },
        ],
      },
      {
        key: `invoiceRows-grandTotal`,
        cells: [
          {
            content: '',
          },
          {
            content: <strong>Grand total</strong>,
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: '',
          },
          {
            content: formatMonetaryNumber(totalGrandPrice) + ' EUR',
          },
          {
            content: '',
          },
          {
            content: '',
          },
        ],
      }
    )

    return rowsItems
  }, [
    rowsFields,
    totalPrice,
    totalIvaAmount,
    totalGrandPrice,
    getServiceCodeOptions,
    isCreditNote,
    isLoadingShipmentEnumeration,
    formValues.rows,
    setIvaAmountValue,
    ivaCodeOptions,
    isLoadingInvoiceEnumerations,
  ])

  return (
    <>
      <Card
        title="Invoice rows"
        className="mb-3 mt-6"
        actions={
          <>
            {isCumulative && (
              <Button
                appearance="link"
                className="ml-auto"
                onClick={() => importRowModalHandlers.open()}
              >
                Import rows
              </Button>
            )}
          </>
        }
      >
        <div className="overflow-auto w-full inline-block -mb-6 removeAtlaskitTableBorder borderOfAtlaskitTableWithTowTotalRow">
          <DynamicTableStateless head={rowsHead} rows={invoiceRows} />
        </div>
      </Card>

      <ImportRowModal isOpen={isOpenImportRowModal} onClose={importRowModalHandlers.close} />
    </>
  )
}

export default CreateInvoiceFormRows
